import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import { useSocket } from "../context/SocketContext";
import { toast } from "react-hot-toast";

const Message = memo(({ message }) => {
  if (message.system)
    return <div className="text-gray-500 italic text-xs px-3">{message.content}</div>;

  return (
    <div className="flex flex-col space-y-1 bg-white/80 backdrop-blur-sm rounded-lg p-3 shadow-sm">
      <div className="flex items-center justify-between">
        <span className="font-medium text-sm text-blue-700">{message.user}</span>
        <span className="text-xs text-gray-500">{message.time}</span>
      </div>
      <div className="text-gray-800 text-sm break-words whitespace-pre-wrap">{message.content}</div>
    </div>
  );
});

Message.displayName = "Message";

function ChatWindow({ room, roomTitle, onClose, initialMessages = [] }) {
  const { socket } = useSocket();
  const namespaceSocketRef = useRef(null);
  const [messages, setMessages] = useState(initialMessages);
  const [newMessage, setNewMessage] = useState("");
  const [isJoined, setIsJoined] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef(null);
  const messagesEndRef = useRef(null);
  const isFirstLoad = useRef(true);

  const scrollToBottom = useCallback((force = false) => {
    if (containerRef.current && (!isFirstLoad.current || force)) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ 
          behavior: force ? "auto" : "smooth",
          block: "end" 
        });
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (!isFirstLoad.current) {
      scrollToBottom();
    }
  }, [messages, scrollToBottom]);

  useEffect(() => {
    if (isFirstLoad.current && !isLoading) {
      isFirstLoad.current = false;
      scrollToBottom(true);
    }
  }, [isLoading, scrollToBottom]);

  const handleNewMessage = useCallback((message) => {
    setMessages((prev) => [
      ...prev,
      {
        user: message.username,
        content: message.message,
        time: message.time,
      },
    ]);
    scrollToBottom();
  }, [scrollToBottom]);

  const handleStatus = useCallback((status) => {
    setMessages((prev) => [
      ...prev,
      {
        system: true,
        content: status.msg,
      },
    ]);
  }, []);

  const handleError = useCallback((error) => {
    console.error("Chat error:", error.msg);
    toast.error(error.msg || "Chat internal error");
  }, []);

  useEffect(() => {
    if (!socket) {
      toast.error("Failed to connect to server");
      return;
    }

    const namespaceSocket = socket.io.socket("/chat", {
      auth: {
        token: localStorage.getItem("accessToken"),
      },
    });
    namespaceSocketRef.current = namespaceSocket;

    const joinRoom = async () => {
      try {
        namespaceSocket.emit("join", { room });
        setIsJoined(true);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to join room:", error);
        toast.error("Failed to join room");
        onClose();
      }
    };

    joinRoom();

    namespaceSocket.on("message", handleNewMessage);
    namespaceSocket.on("status", handleStatus);
    namespaceSocket.on("error", handleError);

    return () => {
      if (namespaceSocketRef.current) {
        namespaceSocketRef.current.off("message", handleNewMessage);
        namespaceSocketRef.current.off("status", handleStatus);
        namespaceSocketRef.current.off("error", handleError);
        namespaceSocketRef.current.disconnect();
        namespaceSocketRef.current = null;
      }
    };
  }, [socket, room, handleNewMessage, handleStatus, handleError, onClose]);

  const sendMessage = useCallback(
    async (e) => {
      e.preventDefault();
      console.log("newMessage", newMessage);
      if (!newMessage.trim() || !isJoined) return;

      try {
        namespaceSocketRef.current.emit("message", {
          room,
          message: newMessage.trim(),
        });

        setNewMessage("");
      } catch (error) {
        console.error("Failed to send:", error);
        toast.error("Failed to send message");
      }
    },
    [newMessage, isJoined, room, socket]
  );

  if (isLoading)
    return (
      <div className="fixed bottom-[120px] right-4 w-[500px] h-[600px] bg-white rounded-lg shadow-lg border flex items-center justify-center z-[9999]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );

  return (
    <div className="fixed bottom-[120px] right-4 w-[500px] bg-gradient-to-br from-blue-50 to-white rounded-lg shadow-xl border border-blue-100/50 transition-all duration-300 hover:shadow-2xl z-[9999]">
      <div className="flex justify-between items-center p-4 border-b bg-white/70 backdrop-blur-sm rounded-t-lg">
        <h3 className="font-semibold text-blue-900">Room: {roomTitle}</h3>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 transition-colors duration-200 text-xl"
          aria-label="Close chat"
        >
          ×
        </button>
      </div>

      <div
        ref={containerRef}
        className="h-[500px] overflow-y-auto p-4 space-y-4 scrollbar-thin scrollbar-thumb-blue-200 scrollbar-track-transparent"
      >
        {messages.map((msg, i) => (
          <div
            key={i}
            className="transition-colors duration-200"
          >
            <Message message={msg} />
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={sendMessage} className="p-4 border-t bg-white/70 backdrop-blur-sm rounded-b-lg">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder={
            isJoined ? "Type a message, enter to send..." : "Connecting..."
          }
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.ctrlKey && !e.metaKey) {
              sendMessage(e);
            }
          }}
          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-blue-400 transition-all duration-200 bg-white/90 backdrop-blur-sm"
          disabled={!isJoined}
        />
      </form>
    </div>
  );
}

export default memo(ChatWindow);
