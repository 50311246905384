// src/components/GuestDynamicTable.jsx

import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
import { GlobalContext } from "../context/GlobalContext";
import { useSocket } from "../context/SocketContext";
import { ProcessSteps } from "./ProcessSteps";

const GuestDynamicTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({ loan_name: "", analyst_name: "" });
  const { globalUserOrganizationAccessName } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { socket, isConnected } = useSocket();

  // Steps array
  const steps = ProcessSteps;
  const customStyles = {
    table: {
      style: {
        backgroundColor: "white",
        borderRadius: "0.5rem",
        boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1)",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#f8fafc",
        borderTopLeftRadius: "0.5rem",
        borderTopRightRadius: "0.5rem",
        borderBottom: "1px solid #e2e8f0",
      },
    },
    headCells: {
      style: {
        fontSize: "0.875rem",
        fontWeight: "600",
        color: "#475569",
        padding: "1rem",
      },
    },
    cells: {
      style: {
        padding: "1rem",
        fontSize: "0.875rem",
        color: "#1e293b",
      },
    },
    rows: {
      style: {
        "&:hover": {
          backgroundColor: "#f1f5f9",
          cursor: "pointer",
        },
        borderBottom: "1px solid #e2e8f0",
      },
    },
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = () => {
      if (isConnected && socket) {
        const fieldsToFetch = [
          "_id",
          "loan_name",
          "progress_step",
          "date_created",
          "analyst_name",
          "last_updated",
          "unread_messages",
        ];
        socket.emit("get_items", {
          fields: fieldsToFetch,
          userOrganizationAccessName: globalUserOrganizationAccessName,
        });

        if (isMounted) {
          socket.on("items", function (items) {
            const reversedData = items.reverse();
            setData(reversedData);
            setFilteredData(reversedData);
          });

          socket.on("new_item", function (item) {
            console.log("[GuestDynamicTable] New item received:", item);
            setData((prevData) => [item, ...prevData]);
            setFilteredData((prevData) => [item, ...prevData]);
          });

          socket.on("update_item", function (item) {
            setData((prevData) =>
              prevData.map((dataItem) =>
                dataItem._id === item._id ? item : dataItem
              )
            );
            setFilteredData((prevData) =>
              prevData.map((dataItem) =>
                dataItem._id === item._id ? item : dataItem
              )
            );
          });

          socket.on("delete_item", function (id) {
            setData((prevData) =>
              prevData.filter((dataItem) => dataItem._id !== id)
            );
            setFilteredData((prevData) =>
              prevData.filter((dataItem) => dataItem._id !== id)
            );
          });

          socket.on("item", function (data) {
            if (data.length > 0) {
              const item = data[0];
              console.log("Item data: ", item);
              console.log("Navigating to guest-individual-loan-tracker");
              console.log("additionalData: ", item.additional_files);
              navigate("/guest-individual-loan-tracker", {
                state: {
                  pdfBase64NorCalTemplate: item.pdfBase64NorCalTemplate,
                  docAiResultsNorCalTemplate: item.docAiResultsNorCalTemplate,
                  pdfBase64: item.pdfBase64,
                  jsonData: item.docAiResults,
                  iframe: item.googleDocsIframe,
                  formId: item._id,
                  additionalData: item.additional_files,
                  progressStep: item.progress_step,
                  loanName: item.loan_name,
                },
              });
            }
          });

          socket.on("disconnect", function () {
            console.log("Disconnected from server");
          });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      if (socket) {
        socket.off("items");
        socket.off("new_item");
        socket.off("update_item");
        socket.off("delete_item");
        socket.off("item");
        socket.off("disconnect");
      }
    };
  }, [socket, isConnected, globalUserOrganizationAccessName]);

  const handleRowClick = (row) => {
    fetchAdditionalDataAndNavigate(row._id);
  };

  const fetchAdditionalDataAndNavigate = (id) => {
    const fieldsToFetch = [
      "pdfBase64",
      "docAiResults",
      "googleDocsIframe",
      "_id",
      "loan_name",
      "analyst_name",
      "additional_files",
      "progress_step",
      "pdfBase64NorCalTemplate",
      "docAiResultsNorCalTemplate",
    ];
    socket.emit("get_items", { id, fields: fieldsToFetch });
  };

  const addItem = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.loan_name) {
      console.log("[GuestDynamicTable] Adding new loan:", formData.loan_name);
      socket.emit("add_item", {
        loan_name: formData.loan_name,
        analyst_name: formData.analyst_name,
        user_organization_access_name: globalUserOrganizationAccessName,
      });
      setIsModalOpen(false);
      setFormData({ loan_name: "", analyst_name: "" });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  const handleDelete = (id) => {
    socket.emit("delete_item", id);
  };

  const searchLoans = (e) => {
    const input = e.target.value.toLowerCase();
    if (input) {
      const filtered = data.filter((item) =>
        item.loan_name?.toLowerCase().includes(input)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  // Define columns
  const columns = [
    {
      name: <span className="text-[#363C45] text-[12px]">Loan Name</span>,
      selector: (row) => row.loan_name || "No Loan Name",
      sortable: true,
      cell: (row) => (
        <div className="flex items-center gap-2 group">
          <span
            className="text-[22px] text-[#363C45] font-[500] leading-[24px] cursor-pointer group-hover:text-blue-600 transition-colors duration-200"
            onClick={() => handleRowClick(row)}
          >
            {row.loan_name || "No Loan Name"}
          </span>
          {row.unread_messages && (
            <div className="flex items-center">
              <div className="relative flex h-5 items-center">
                <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-blue-400 opacity-75"></span>
                <span className="relative flex h-3 w-3 rounded-full bg-blue-500">
                  <svg className="absolute -right-4 -top-4 h-5 w-5 text-blue-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
                  </svg>
                </span>
              </div>
            </div>
          )}
        </div>
      ),
      width: "25%",
    },
    {
      name: "Current Status",
      selector: (row) => {
        const step = steps.find((step) => step.id === row.progress_step);
        let status = step
          ? `${step.title} (${row.progress_step}/18)`
          : "Unknown Status";
        if (row.progress_step === 10) {
          status = "Completed ";
        } else if (row.progress_step === 6) {
          status = "CAR Approved -Answer Additional Questions";
        } else if (row.progress_step > 0 && row.progress_step < 10) {
          status = "Processing Paperwork";
        }
        return status;
      },
      sortable: true,
      cell: (row) => {
        const step = steps.find((step) => step.id === row.progress_step);
        let status = step
          ? `${step.title} (${row.progress_step}/18)`
          : "Unknown Status";
        if (row.progress_step === 10) {
          status = "Completed ";
        } else if (row.progress_step === 6) {
          status = "CAR Approved -Answer Additional Questions";
        } else if (row.progress_step > 0 && row.progress_step < 10) {
          status = "Processing Paperwork";
        }
        return (
          <div>
            <span className="text-[#363C45] font-[500] text-[15px]">
              {status}
            </span>
            <ProgressBar currentStep={row.progress_step} steps={steps} />
          </div>
        );
      },
      width: "35%",
    },
    {
      name: <span className="text-[#363C45] text-[12px]">Analyst</span>,
      selector: (row) => row.analyst_name || "Not Assigned",
      sortable: true,
      cell: (row) => (
        <span className="text-[14px] text-[#535C69] font-[400] leading-[24px]">
          {row.analyst_name || "Not Assigned"}
        </span>
      ),
      width: "12%",
    },
    {
      name: <span className="text-[#363C45] text-[12px]">Last Updated</span>,
      selector: (row) => row.last_updated,
      sortable: true,
      cell: (row) => (
        <span className="text-[14px] text-[#535C69] font-[400] leading-[24px]">
          {row.last_updated
            ? moment(row.last_updated).format("MMM D, YYYY")
            : "N/A"}
        </span>
      ),
      width: "12%",
    },
    {
      name: "Created",
      selector: (row) => moment(row.date_created).format("MMM D, YYYY"),
      sortable: true,
      cell: (row) => (
        <span className="text-[14px] text-[#535C69] font-[400] leading-[24px]">
          {moment(row.date_created).format("MMM D, YYYY")}
        </span>
      ),
      width: "10%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="ms-2">
          <button
            className="text-red-600 hover:text-red-800 transition flex items-center justify-center"
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(row._id);
            }}
          >
            <img src="/images/Trash.svg" width={16} height={16} alt="Delete" />
          </button>
        </div>
      ),
      width: "6%",
    },
  ];

  return (
    <div className="p-6" style={{ maxWidth: "85vw", margin: "0 auto" }}>
      {/* <div className="mb-6">
                <h1 className="text-2xl font-bold text-gray-900">Loan Directory</h1>
                <p className="mt-2 text-gray-600">Track and manage your loan applications</p>
            </div> */}

      <div className="bg-white rounded-lg shadow">
        <div className="flex justify-between items-center p-4">
          <div className="flex gap-2 items-center">
            <h2 className="text-[36px] font-[500] text-[#363C45]">
              Loan Directory
            </h2>
            <span className="text-[#535C69] text-[12px] font-[500] mt-4">
              {filteredData.length} Loans
            </span>
          </div>
          <div className="flex space-x-4">
            <div className="relative">
              <input
                type="text"
                id="searchBar"
                className="border border-gray-300 rounded-md p-2 text-sm ps-6"
                placeholder="Search loans"
                onChange={searchLoans}
              />
              <img
                src="/images/MagnifyingGlass.svg"
                width={16}
                height={16}
                className="absolute top-[12px] left-2"
                alt=""
              />
            </div>
            <button
              onClick={addItem}
              className="bg-[#2183F6] text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
            >
              Add New Loan +
            </button>
          </div>
        </div>
        <div>
          <p className="text-[12px] text-[#535c69] px-4 mb-3">
            Displaying {filteredData.length} results
          </p>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            defaultSortField="loan_name"
            className="table-auto w-full bg-transparent"
            onRowClicked={handleRowClick}
            highlightOnHover
            pointerOnHover
            customStyles={{
              ...customStyles,
              table: {
                ...customStyles.table,
                style: {
                  ...customStyles.table.style,
                  minWidth: "1300px",
                },
              },
            }}
            conditionalRowStyles={[
              {
                when: (row) => steps[row.progress_step - 1]?.pending,
                style: {
                  backgroundColor: "#FFFF99", // Light yellow color for pending rows
                },
              },
            ]}
          />
        </div>
      </div>

      {/* Add Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900">
                Add New Loan
              </h2>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="loan_name"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Loan Name *
                </label>
                <input
                  type="text"
                  id="loan_name"
                  name="loan_name"
                  value={formData.loan_name}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter loan name"
                  required
                  autoFocus
                />
              </div>

              <div>
                <label
                  htmlFor="analyst_name"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Analyst Name
                </label>
                <input
                  type="text"
                  id="analyst_name"
                  name="analyst_name"
                  value={formData.analyst_name}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter analyst name"
                />
              </div>

              <div className="flex justify-end space-x-3 mt-6">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Add Loan
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const ProgressBar = ({ currentStep, steps }) => {
  // Ensure currentStep is a valid number
  const validCurrentStep = Number(currentStep) || 0;
  const totalSteps = (steps?.length || 1) - 1;

  // Ensure we don't create arrays with negative lengths
  const filledBlocks = Math.max(0, Math.min(validCurrentStep, totalSteps));
  const unfilledBlocks = Math.max(0, totalSteps - filledBlocks);

  // Check if current step is pending
  const isCurrentStepPending = steps[validCurrentStep - 1]?.pending;

  return (
    <div className="flex items-center my-2 flex-wrap gap-y-[0.5rem]">
      {/* Filled Blocks (Green) */}
      {Array.from({ length: Math.max(0, filledBlocks - 1) }).map((_, index) => (
        <div
          key={`filled-${index}`}
          className="w-[36px] h-[8px] bg-[#0BBC78] mr-1 rounded-full"
        />
      ))}
      {/* Last Filled Block (Blue or Yellow based on pending status) */}
      {filledBlocks > 0 && (
        <div
          key={`filled-last`}
          className={`w-[36px] h-[8px] mr-1 rounded-full
                        ${
                          isCurrentStepPending
                            ? "bg-yellow-400"
                            : "bg-[#0B51BC]"
                        }`}
        />
      )}
      {/* Unfilled Blocks (Gray) */}
      {Array.from({ length: unfilledBlocks }).map((_, index) => (
        <div
          key={`unfilled-${index}`}
          className="w-[36px] h-[8px] bg-gray-300 mr-1 rounded-full"
        />
      ))}
    </div>
  );
};

export default GuestDynamicTable;
