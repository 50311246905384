import React, { useState, useEffect, useRef, useCallback } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { highlightPlugin } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useTheme } from "../context/ThemeContext";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useSocket } from "../context/SocketContext";
import RectButton from "./RectButton";
import { requiredKeys } from "./FilterKeys";
import { dateFields, yesNoFields, addressFields, optionallyEmptyFields } from "./FilterKeys";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

Modal.setAppElement("#root");

const CustomPDFViewerTables = () => {
  const location = useLocation();
  const {
    pdfBase64: initialPdfUrl,
    jsonData: initialJsonData,
    formId: initialFormId,
  } = location.state || {};
  const navigate = useNavigate();

  const [pdfUrl, setPdfUrl] = useState();
  const [pdfBase64, setPdfBase64] = useState(initialPdfUrl || null);
  const [jsonData, setJsonData] = useState({});
  const [formId, setFormId] = useState(initialFormId || null);
  const [highlightedAreas, setHighlightedAreas] = useState([]);
  const [temporaryHighlights, setTemporaryHighlights] = useState([]);
  const [isHighlightVisible, setIsHighlightVisible] = useState(true);
  const [showAllHighlights, setShowAllHighlights] = useState(true);
  const { darkMode, toggleTheme } = useTheme();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leadsSelection, setLeadsSelection] = useState("two");
  const classNameForFieldsWithInvalidEntries = "error-in-input-field";
  const classNameForRequiredFields = "required-input-field";
  const classNameForRequiredMemoDetailCards = "required-memo-detail-cards";
  const classNameForMemoDetailInputFields = "memo-details-input";
  const classNameForMemoDetailCards = "memo-details-card";
  const themeStyles = {
    backgroundColor: darkMode ? "#121212" : "#FFFFFF",
    color: darkMode ? "white" : "black",
    borderColor: darkMode ? "#1e1e1e" : "#CCCCCC",
    buttonBackgroundColor: "#6A55FE",
  };

  const { socket, isConnected } = useSocket();

  const isMounted = useRef(true);
  const socketRef = useRef(null);
  const navigationInProgress = useRef(false);

  // Add new state for email sending status
  const [emailStatus, setEmailStatus] = useState('idle'); // idle, sending, success, error
  const [emailError, setEmailError] = useState(null);

  useEffect(() => {
    isMounted.current = true;
    socketRef.current = socket;
    return () => {
      isMounted.current = false;
      if (socketRef.current) {
        socketRef.current.off("form_copilot_response");
        socketRef.current.off("json_data");
        socketRef.current.off("processed_pdfs");
        socketRef.current.off("minutes_generated");
        socketRef.current.off("error");
        socketRef.current.off("item");
        socketRef.current.off("email_sent");
      }
    };
  }, []);

  const handleNavigation = useCallback(
    (path) => {
      if (!isMounted.current || navigationInProgress.current) return;

      try {
        navigationInProgress.current = true;
        navigate(path, { replace: true });
      } catch (error) {
        console.error("[CustomPDFViewerTables] Navigation error:", error);
        navigationInProgress.current = false;
      }
    },
    [navigate]
  );

  const zoomPluginInstance = zoomPlugin();
  const highlightPluginInstance = highlightPlugin({
    renderHighlights: (props) => {
      return (
        <div>
          {showAllHighlights &&
            highlightedAreas.map(
              (area, idx) =>
                area.pageIndex === props.pageIndex && (
                  <div
                    key={idx}
                    style={{
                      background: "#2183F6",
                      opacity: 0.5,
                      position: "absolute",
                      top: `${area.top * 100}%`,
                      left: `${area.left * 100}%`,
                      height: `${area.height * 100}%`,
                      width: `${area.width * 100}%`,
                      pointerEvents: "none",
                      transform: `rotate(${props.rotation}deg)`,
                      transition: "opacity 2s",
                    }}
                  />
                )
            )}
          {temporaryHighlights.map(
            (area, idx) =>
              area.pageIndex === props.pageIndex && (
                <div
                  key={idx}
                  style={{
                    background: "#2183F6",
                    opacity: isHighlightVisible ? 0.5 : 0,
                    position: "absolute",
                    top: `${area.top * 100}%`,
                    left: `${area.left * 100}%`,
                    height: `${area.height * 100}%`,
                    width: `${area.width * 100}%`,
                    pointerEvents: "none",
                    transform: `rotate(${props.rotation}deg)`,
                    transition: "opacity 2s",
                  }}
                />
              )
          )}
        </div>
      );
    },
  });

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  useEffect(() => {
    console.log("[CustomPDFViewerTables] Checking pdfBase64 state:", pdfBase64);
    if (pdfBase64) {
      try {
        console.log(
          "[CustomPDFViewerTables] pdfBase64 exists:",
          pdfBase64.slice(0, 30)
        );
        const byteCharacters = atob(pdfBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });

        const objectUrl = URL.createObjectURL(blob);
        console.log("[CustomPDFViewerTables] objectUrl:", objectUrl);
        setPdfUrl(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error(
          "[CustomPDFViewerTables] Error converting base64 to Blob:",
          error
        );
      }
    } else {
      console.log("[CustomPDFViewerTables] pdfBase64 is empty or null");
    }
  }, [pdfBase64]);

  useEffect(() => {
    if (initialPdfUrl && initialJsonData) {
      console.log(
        "[CustomPDFViewerTables] Received initial JSON data:",
        initialJsonData
      );
      setJsonData(initialJsonData.documents[0].fields);
      console.log(initialJsonData.documents[0].fields);
      extractAndHighlightFromJson(initialJsonData.documents[0].fields);
    }
  }, [initialPdfUrl, initialJsonData]);

  useEffect(() => {
    if (isConnected) {
      console.log("[CustomPDFViewerTables] Socket is connected");
      socket.emit("get_nor_cal_template_table", { formid: formId });

      socket.on("form_copilot_response", (response) => {
        console.log(
          "[CustomPDFViewerTables] form_copilot_response received:",
          response
        );
        if (
          response &&
          response.documents &&
          response.documents[0] &&
          response.documents[0].fields
        ) {
          socket.emit("get_nor_cal_template_table", { formid: formId });
          console.log(formId);
          setJsonData(response.documents[0].fields);
          extractAndHighlightFromJson(response.documents[0].fields);
        } else {
          console.error("Invalid response structure:", response);
          setJsonData({ error: "Invalid response structure" });
        }
        setIsProcessing(false);
        setLoading(false);
      });

      socket.on("json_data", (data) => {
        console.log("[CustomPDFViewerTables] Received JSON data:", data);
        console.log(data);
        // Set default values for specific fields only if they don't already have values
        const defaultFields = {
          'Risk_Insurance': 'Required',
          'Fire_Insurance': 'Required',
          'Broad_Form_Security_UCC': 'Required'
        };
        
        const updatedData = { ...data };
        Object.entries(defaultFields).forEach(([key, defaultValue]) => {
          if (!updatedData[key]?.valueString) {
            updatedData[key] = {
              ...updatedData[key],
              valueString: defaultValue
            };
            // Also emit the modification to the server
            if (socket && formId) {
              socket.emit("modify_item", {
                formid: formId,
                key: key,
                value: defaultValue
              });
            }
          }
        });
        
        setJsonData(updatedData);
        console.log("setting initial json data");
        setIsProcessing(false);
        setLoading(false);
      });

      socket.on("processed_pdfs", (data) => {
        console.log("[CustomPDFViewerTables] Received processed PDFs:", data);
        data.forEach((file) => downloadFile(file.file_name, file.base64pdf));
      });

      socket.on("minutes_generated", (data) => {
        console.log("[CustomPDFViewerTables] Minutes generated:", data);
        downloadFile(data.file_name, data.base64docx);
      });

      // Add new socket listeners for email status
      socket.on("email_sent", (data) => {
        console.log("[CustomPDFViewerTables] Email sent successfully:", data);
        setEmailStatus('success');
        // Reset status after 3 seconds
        setTimeout(() => setEmailStatus('idle'), 3000);
      });

      socket.on("error", (error) => {
        console.error("Socket error:", error.msg);
        setJsonData({ error: error.msg });
        setIsProcessing(false);
        setLoading(false);
        // Add email error handling
        if (emailStatus === 'sending') {
          setEmailStatus('error');
          setEmailError(error.msg);
          // Reset status after 3 seconds
          setTimeout(() => {
            setEmailStatus('idle');
            setEmailError(null);
          }, 3000);
        }
      });

      return () => {
        socket.off("form_copilot_response");
        socket.off("json_data");
        socket.off("processed_pdfs");
        socket.off("minutes_generated");
        socket.off("error");
        socket.off("item");
        socket.off("email_sent");
      };
    }
  }, [isConnected, socket]);

  const [isProcessing, setIsProcessing] = useState(false);

  const ProcessingOverlay = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500 mx-auto mb-4"></div>
        <h2 className="text-xl font-semibold mb-2">Processing Document</h2>
        <p className="text-gray-600">
          Please wait while we analyze your document...
        </p>
      </div>
    </div>
  );

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsProcessing(true);
    setLoading(true);

    const fileUrl = URL.createObjectURL(file);
    setPdfUrl(fileUrl);
    setFileName(file.name);

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      const base64Content = base64String.split(",")[1];
      const isDivisibleBy4 = base64Content.length % 4 === 0;
      let paddedBase64Content = base64Content;
      if (!isDivisibleBy4) {
        const missingPadding = 4 - (base64Content.length % 4);
        paddedBase64Content += "=".repeat(missingPadding);
      }
      const base64StringWithPadding = `${
        base64String.split(",")[0]
      },${paddedBase64Content}`;
      console.log(
        "[CustomPDFViewerTables] emitting form_copilot with formId: ",
        formId
      );

      if (!socket) {
        console.error("Socket not connected");
        setIsProcessing(false);
        setLoading(false);
        return;
      }

      socket.emit("form_copilot", {
        data: {
          body: base64StringWithPadding,
          formId: formId,
          documentType: "norcal-template-1",
        },
      });
    };

    reader.onerror = (error) => {
      console.error("[CustomPDFViewerTables] Error reading file:", error);
      setJsonData({ error: error.toString() });
      setIsProcessing(false);
      setLoading(false);
    };

    reader.readAsDataURL(file);
  };

  const extractAndHighlightFromJson = (jsonData) => {
    const highlightedAreas = [];

    Object.entries(jsonData).forEach(([key, field]) => {
      if (field.boundingRegions) {
        field.boundingRegions.forEach((region) => {
          const polygon = region.polygon;
          const [x1, y1, x2, y2, x3, y3, x4, y4] = polygon;
          const left = Math.min(x1, x2, x3, x4);
          const top = Math.min(y1, y2, y3, y4);
          const width = Math.max(x1, x2, x3, x4) - left;
          const height = Math.max(y1, y2, y3, y4) - top;

          highlightedAreas.push({
            top: top / 11,
            left: left / 8.5,
            height: height / 11,
            width: width / 8.5,
            pageIndex: region.pageNumber - 1,
            fieldKey: key,
          });
        });
      }
    });

    setHighlightedAreas(highlightedAreas);
  };

  const handlePercentages = (x) => {
    return Math.round(x);
  };

  const handlePanelClick = (boundingRegions, event) => {
    // Check if the click was on an input/textarea element
    if (
      event &&
      (event.target.tagName === "INPUT" || event.target.tagName === "TEXTAREA")
    ) {
      return; // Don't clear highlights if clicking on input fields
    }

    setShowAllHighlights(false);
    setHighlightedAreas([]);
    if (boundingRegions && boundingRegions.length > 0) {
      const region = boundingRegions[0];
      const pageIndex = region.pageNumber - 1;
      jumpToPage(pageIndex);

      const [x1, y1, x2, y2, x3, y3, x4, y4] = region.polygon;
      const left = Math.min(x1, x2, x3, x4);
      const top = Math.min(y1, y2, y3, y4);
      const width = Math.max(x1, x2, x3, x4) - left;
      const height = Math.max(y1, y2, y3, y4) - top;

      const highlightedArea = {
        top: top / 11,
        left: left / 8.5,
        height: height / 11,
        width: width / 8.5,
        pageIndex: pageIndex,
      };

      setTemporaryHighlights([highlightedArea]);
      setIsHighlightVisible(true);
      setTimeout(() => {
        setIsHighlightVisible(false);
        setTimeout(() => {
          setTemporaryHighlights([]);
        }, 2000);
      }, 1000);
    }
  };

  const toggleShowAllHighlights = () => {
    setShowAllHighlights(!showAllHighlights);
  };

  useEffect(() => {
    if (showAllHighlights) {
      setTemporaryHighlights([]);
      setIsHighlightVisible(false);
    }
  }, [showAllHighlights]);

  useEffect(() => {
    determineErrorsInAllRequiredInputs();
  });

  const handleInputChange = (key, newValue) => {
    let valueToSet =
      typeof newValue === "object" ? JSON.stringify(newValue) : newValue;

    // Convert date format for date fields
    if (dateFields.includes(key) || key.toLowerCase().includes("date")) {
      console.log(
        `[Date Field] Processing date for ${key}. Initial value:`,
        newValue
      );
      // If it's a valid date string, convert it to MM/DD/YYYY
      if (newValue) {
        try {
          // Create date object and adjust for timezone
          const [yearStr, monthStr, dayStr] = newValue
            .split("-")
            .map((num) => parseInt(num, 10));
          const date = new Date(yearStr, monthStr - 1, dayStr);
          console.log("[Date Field] Created date object:", date);

          // Format the date without timezone conversion
          valueToSet = `${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${date
            .getDate()
            .toString()
            .padStart(2, "0")}/${date.getFullYear()}`;
          console.log(
            `[Date Field] Formatted for server (MM/DD/YYYY):`,
            valueToSet
          );
        } catch (error) {
          console.error("[Date Field] Error formatting date:", error);
        }
      }
    }

    setJsonData((prevJsonData) => {
      const updatedJsonData = {
        ...prevJsonData,
        [key]: {
          ...prevJsonData[key],
          valueString: valueToSet,
        },
      };
      if (socket) {
        console.log("[CustomPDFViewerTables] Emitting modify_item event:", {
          formid: formId,
          key,
          value: valueToSet,
          isDateField:
            dateFields.includes(key) || key.toLowerCase().includes("date"),
        });
        socket.emit("modify_item", {
          formid: formId,
          key: key,
          value: valueToSet,
        });
      }
      return updatedJsonData;
    });
  };

  function determineErrorsInAllRequiredInputs() {
    const allMemoDetailsInputFields = document.querySelectorAll(
      `.${classNameForRequiredFields}`
    );
    allMemoDetailsInputFields.forEach((elemTarget) => {
      if (determineError(elemTarget)) {
        warnUserOfIncompleteField(elemTarget);
      }
    });
  }

  const handleGeneratePDFs = () => {
    determineErrorsInAllRequiredInputs();
    const inputFieldWithError = document.querySelector(
      "." + classNameForFieldsWithInvalidEntries
    );
    
    if (inputFieldWithError) {
      const fieldName = inputFieldWithError.getAttribute('name');
      // Only block submission if the field with error is not optionally empty
      if (!optionallyEmptyFields.includes(fieldName)) {
        alert("Please view memo fields for invalid entries and try again.");
        return;
      }
    }
    
    if (socket && formId) {
      console.log(
        "[CustomPDFViewerTables] Emitting generate_workflow_pdfs event"
      );
      socket.emit("generate_workflow_pdfs", { form_id: formId });
    }
  };

  const handleGenerateInvoices = () => {
    setIsModalOpen(true);
  };

  const handleDownloadInvoices = () => {
    if (socket && formId) {
      console.log(
        "[CustomPDFViewerTables] Emitting generate_nor_cal_invoice event"
      );
      socket.emit("generate_nor_cal_invoice", {
        form_id: formId,
        two_or_three_people: leadsSelection,
      });
    }
    console.log("[CustomPDFViewerTables] generate_nor_cal_invoice");
    setIsModalOpen(false);
  };

  const handleGenerateMinutes = () => {
    if (socket && formId) {
      console.log(
        "[CustomPDFViewerTables] Emitting generate_250k_less_minutes event through WebSocket"
      );
      socket.emit("generate_250k_less_minutes", { form_id: formId });
      console.log(
        "[CustomPDFViewerTables] Emitting generate_250k_less_minutes event through WebSocket success"
      );
    } else {
      console.error(
        "[CustomPDFViewerTables] Socket is not connected or formId is missing."
      );
    }
  };

  const downloadFile = (fileName, base64Content) => {
    const extension = fileName.split(".").pop();
    let mimeType;

    switch (extension) {
      case "pdf":
        mimeType = "application/pdf";
        break;
      case "xlsx":
        mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case "docx":
        mimeType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      default:
        mimeType = "application/octet-stream";
    }

    const linkSource = `data:${mimeType};base64,${base64Content}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const toggleFilter = () => {
    setFilterEnabled(!filterEnabled);
    const allMemoDetailCards = document.querySelectorAll(
      `.${classNameForMemoDetailCards}`
    );
    if (filterEnabled) {
      allMemoDetailCards.forEach((elem) => {
        if (!elem.classList.contains(classNameForRequiredMemoDetailCards)) {
          elem.classList.add("hidden");
        }
      });
    } else {
      allMemoDetailCards.forEach((elem) => {
        if (!elem.classList.contains(classNameForRequiredMemoDetailCards)) {
          elem.classList.remove("hidden");
        }
      });
    }
  };

  const renderKeyLabel = (key) => {
    return key.replace(/_/g, " ");
  };

  function determineError(elemTarget) {
    var elemValue = elemTarget.value;
    const fieldName = elemTarget.name;
    
    // If it's an optionally empty field, show validation UI but don't block form submission
    if (optionallyEmptyFields.includes(fieldName)) {
      return elemValue === "";
    }
    
    // First things first: Is this field empty?
    if (elemValue === "") {
      return true;
    }
    
    // Check if the field is an address field
    if (addressFields.includes(fieldName)) {
      const commaCount = (elemValue.match(/,/g) || []).length;
      if (commaCount !== 2 && elemValue.trim() !== "") {
        return true;
      }
    }
    return false;
  }

  function warnUserOfIncompleteField(elemTarget) {
    console.log("Incomplete Field:", elemTarget);
    elemTarget.classList.add("input-field-error");
    elemTarget.classList.add(classNameForFieldsWithInvalidEntries);
    const errorTextDirectlyUnderInputField = elemTarget.nextElementSibling;
    errorTextDirectlyUnderInputField.classList.remove("hidden");
    var inputFieldErrorText;
    if (addressFields.includes(elemTarget.name)) {
      inputFieldErrorText =
        "Please format address as: <Street>, <City>, <State> <Zip>";
    } else if (optionallyEmptyFields.includes(elemTarget.name)) {
      inputFieldErrorText = "Optional field";
    } else {
      inputFieldErrorText = "Invalid entry";
    }
    errorTextDirectlyUnderInputField.textContent = inputFieldErrorText;
    return;
  }

  function removeWarningOfIncompleteField(elemTarget) {
    if (
      elemTarget.classList.contains("input-field-error") ||
      elemTarget.classList.contains(classNameForFieldsWithInvalidEntries)
    ) {
      elemTarget.classList.remove("input-field-error");
      elemTarget.classList.remove(classNameForFieldsWithInvalidEntries);
    }
    const errorTextDirectlyUnderInputField = elemTarget.nextElementSibling;
    errorTextDirectlyUnderInputField.classList.add("hidden");
    errorTextDirectlyUnderInputField.textContent = "";
    return;
  }

  const adjustTextareaHeight = (element) => {
    element.style.height = '0px';
    const newHeight = element.scrollHeight;
    element.style.height = `${newHeight}px`;
  };

  const renderInputField = (key, value) => {
    const isRequired = requiredKeys.includes(key);
    const isOptional = optionallyEmptyFields.includes(key);
    const isDateField =
      key.toLowerCase().includes("date") || dateFields.includes(key);
    const isYesNoField = yesNoFields.includes(key);

    // Set default values for specific yesNoFields
    const defaultToRequired = ['Risk_Insurance', 'Fire_Insurance', 'Broad_Form_Security_UCC'];
    if (isYesNoField && defaultToRequired.includes(key) && !value?.valueString && !value) {
      value = { valueString: "Required" };
    }

    // Convert MM/DD/YYYY to YYYY-MM-DD for date input
    const formatDateForInput = (dateStr) => {
      if (!dateStr) return "";
      try {
        const [month, day, year] = dateStr.split("/");
        if (month && day && year) {
          return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        }
        return dateStr;
      } catch (error) {
        console.error("Error parsing date:", error);
        return dateStr;
      }
    };

    return (
      <div className={`relative`}>
        {isDateField ? (
          <input
            type="date"
            value={formatDateForInput(
              typeof value === "object" ? value.valueString : value
            )}
            name={key}
            onChange={(e) => {
              handleInputChange(key, e.target.value);
              if (isRequired || isOptional) {
                determineError(e.target)
                  ? warnUserOfIncompleteField(e.target)
                  : removeWarningOfIncompleteField(e.target);
              }
            }}
            onBlur={(e) => {
              if (isOptional && !e.target.value) {
                warnUserOfIncompleteField(e.target);
              }
            }}
            className={`${classNameForMemoDetailInputFields} memo-details-textarea input-field ${
              isRequired || isOptional ? classNameForRequiredFields : ""
            }`}
          />
        ) : isYesNoField ? (
          <div className="flex gap-2">
            <label className="text-[#535C69]">
              <input
                type="checkbox"
                className="mr-2"
                name={key}
                checked={
                  value?.valueString === "Required" || value === "Required"
                }
                onChange={(e) => {
                  handleInputChange(
                    key,
                    e.target.checked ? "Required" : "Not Required"
                  );
                  if (isRequired || isOptional) {
                    determineError(e.target)
                      ? warnUserOfIncompleteField(e.target)
                      : removeWarningOfIncompleteField(e.target);
                  }
                }}
              />
              Required
            </label>
          </div>
        ) : (
          <textarea
            value={typeof value === "object" ? value.valueString : value}
            name={key}
            onChange={(e) => {
              handleInputChange(key, e.target.value);
              if (isRequired || isOptional) {
                determineError(e.target)
                  ? warnUserOfIncompleteField(e.target)
                  : removeWarningOfIncompleteField(e.target);
              }
            }}
            onBlur={(e) => {
              if (isOptional && !e.target.value) {
                warnUserOfIncompleteField(e.target);
              }
            }}
            style={{ whiteSpace: 'pre-wrap', overflow: 'hidden' }}
            ref={(el) => {
              if (el) {
                adjustTextareaHeight(el);
              }
            }}
            onInput={(e) => adjustTextareaHeight(e.target)}
            className={`${classNameForMemoDetailInputFields} memo-details-textarea input-field ${
              isRequired || isOptional ? classNameForRequiredFields : ""
            }`}
          />
        )}
        <p className="error-text hidden memo-details-input-error-text text-red-700 mt-[5px]"></p>
      </div>
    );
  };

  const handleSendEmail = () => {
    if (socket && formId) {
      setEmailStatus('sending');
      setEmailError(null);
      console.log("[CustomPDFViewerTables] Emitting old_way_send_6_questions_email event");
      socket.emit("old_way_send_6_questions_email", { formId: formId });
    }
  };

  const menuToolBarContainer = (
    <div
      className="menu-toolbar-container"
      style={{
        padding: "10px 0px",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        justifyContent: "space-between",
      }}
    >
      <button
        className="navigate-back-button w-[44px] h-[44px] bg-white flex items-center justify-center rounded-[0.25rem]"
        onClick={() => {
          if (isMounted.current) {
            const returnPath =
              location.state?.returnPath || "/individual-loan-tracker";
            navigate(returnPath);
          }
        }}
      >
        <img src="/images/ArrowLeft.svg" width={24} height={24} alt="Go Back" />
      </button>
      <div className="flex items-center gap-1">
        <label
          htmlFor="file-upload"
          className="generate-ibank-pdf-buttons cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[0.25rem] flex items-center gap-1"
        >
          Upload Document{" "}
          <img
            src="/images/FileArrowUp.svg"
            width={16}
            height={16}
            alt="UploadFile"
          />
        </label>
        <input
          id="file-upload"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept="application/pdf"
          required
        />
        <RectButton
          className="generate-pdf-button"
          onClick={handleGeneratePDFs}
          content={"Generate Ibank PDFs"}
        />

        <RectButton
          className="generate-invoices-button"
          onClick={handleGeneratePDFs}
          content={"Generate Commissions"}
        />

        <RectButton
          className="generate-minutes-button"
          onClick={handleGenerateMinutes}
          content={"Generate Minutes"}
        />

        <RectButton
          className="send-email-button"
          onClick={handleSendEmail}
          content={
            emailStatus === 'sending' ? (
              <div className="flex items-center gap-2">
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Sending...
              </div>
            ) : emailStatus === 'success' ? (
              <div className="flex items-center gap-2">
                <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Sent!
              </div>
            ) : emailStatus === 'error' ? (
              <div className="flex items-center gap-2">
                <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
                Failed
              </div>
            ) : (
              "Send Email"
            )
          }
          disabled={emailStatus === 'sending'}
        />

        <RectButton
          className="filter-button"
          onClick={toggleFilter}
          content={`Filter: ${filterEnabled ? "ON" : "OFF"}`}
        />

        <button
          className="w-[44px] h-[44px] bg-white flex items-center justify-center rounded-[0.25rem]"
          onClick={() => {
            if (isMounted.current) {
              navigate("/individual-loan-tracker", {
                state: {
                  pdfBase64: pdfBase64,
                  jsonData: { documents: [{ fields: jsonData }] },
                  formId: formId,
                  progressStep: location.state?.progressStep,
                  pdfBase64NorCalTemplate: location.state?.pdfBase64,
                  docAiResultsNorCalTemplate: location.state?.jsonData,
                },
                replace: true,
              });
            }
          }}
        >
          <img
            src="/images/HouseSimple.svg"
            width={24}
            height={24}
            alt="Go Back"
          />
        </button>
      </div>
    </div>
  );

  return (
    <div className="bg-[#f1f1f1]">
      {isProcessing && <ProcessingOverlay />}
      <div className="sm:max-lg:block hidden">{menuToolBarContainer}</div>
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        <div
          style={{ width: "65%", padding: "2px 1rem", position: "relative" }}
        >
          <div>
            <div className="sm:max-lg:hidden block">{menuToolBarContainer}</div>
          </div>
          {pdfUrl && (
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100vh",
                overflow: "hidden",
              }}
            >
              <Worker workerUrl={pdfjsWorker}>
                <div style={{ height: "100%", overflow: "hidden" }}>
                  <Viewer
                    fileUrl={pdfUrl}
                    plugins={[
                      zoomPluginInstance,
                      highlightPluginInstance,
                      pageNavigationPluginInstance,
                    ]}
                    onDocumentLoad={() => console.log("Document loaded")}
                    defaultScale={0.95}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    <ZoomOutButton />
                    <ZoomPopover />
                    <ZoomInButton />
                  </div>
                </div>
              </Worker>
            </div>
          )}
        </div>
        <div
          className="px"
          style={{
            padding: "0px 1rem",
            width: "35%",
            marginTop: "10px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              overflowY: "auto",
              height: "calc(100vh - 32px)",
            }}
            className="Details"
          >
            {jsonData ? (
              <>
                {Object.entries(jsonData)
                  .filter(([key]) => key !== "_id")
                  .sort(([keyA], [keyB]) => {
                    // First, check if either key is in optionallyEmptyFields
                    const isAOptional = optionallyEmptyFields.includes(keyA);
                    const isBOptional = optionallyEmptyFields.includes(keyB);
                    
                    // If one is optional and the other isn't, sort the optional one first
                    if (isAOptional && !isBOptional) return -1;
                    if (!isAOptional && isBOptional) return 1;
                    
                    // If both are optional or both are not optional, maintain alphabetical order
                    return keyA.localeCompare(keyB);
                  })
                  .map(([key, value], index) => (
                      <div
                        key={index}
                        onClick={(event) =>
                          handlePanelClick(value.boundingRegions, event)
                        }
                        className={`${classNameForMemoDetailCards} ${
                          requiredKeys.includes(key)
                            ? classNameForRequiredMemoDetailCards
                            : ""
                        }`}
                        style={{
                          padding: "10px",
                          backgroundColor: "white",
                          color: "black",
                          cursor: "pointer",
                          position: "relative",
                          width: "100%",
                          margin: "2px 0px",
                          borderRadius: "0.25rem",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                          }}
                        >
                          <h4
                            className="memo-details-header"
                            style={{
                              textTransform: "capitalize",
                              margin: "0",
                              color: "#535C69",
                              flex: "1 1 auto",
                              maxWidth: "70%",
                            }}
                          >
                            {renderKeyLabel(key)}
                            {requiredKeys.includes(key) || optionallyEmptyFields.includes(key) ? (
                              <span className="requiredAsterisk text-red-700 weight-700">
                                {" "}
                                *
                              </span>
                            ) : (
                              <></>
                            )}
                          </h4>
                          {value.confidence !== undefined && (
                            <p
                              className="memo-details-percentage"
                              style={{ margin: "0", flex: "0 1 auto" }}
                            >
                              {handlePercentages(value.confidence * 100)}%
                            </p>
                          )}
                        </div>
                        {renderInputField(key, value)}
                      </div>
                    ))}
              </>
            ) : (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading ? (
                    <i className="fas fa-spinner fa-spin fa-3x"></i>
                  ) : (
                    <h2>Please Upload Document</h2>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Select Leads"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "300px",
              padding: "20px",
              textAlign: "center",
            },
          }}
        >
          <h2>Select Leads</h2>
          <div>
            <label>
              <input
                type="radio"
                value="two"
                checked={leadsSelection === "two"}
                onChange={(e) => setLeadsSelection(e.target.value)}
              />
              Two Leads
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                value="three"
                checked={leadsSelection === "three"}
                onChange={(e) => setLeadsSelection(e.target.value)}
              />
              Three Leads
            </label>
          </div>
          <button
            onClick={handleDownloadInvoices}
            style={{
              marginTop: "20px",
              padding: "10px 20px",
              backgroundColor: themeStyles.buttonBackgroundColor,
              color: "white",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            Download Invoices
          </button>
        </Modal>
      </div>
    </div>
  );
};

export default CustomPDFViewerTables;
