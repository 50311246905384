// src/context/SocketContext.js
import React, { useMemo, useEffect, createContext, useContext, useRef, useState } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext(null);

export const useSocket = () => {
    const context = useContext(SocketContext);
    if (!context) {
        console.error('useSocket must be used within a SocketProvider');
        return null;
    }
    return context;
};

export const SocketProvider = ({ children }) => {
    const socketRef = useRef(null);
    const [isConnected, setIsConnected] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const initializeSocket = () => {
        if (socketRef.current) return;

        const newSocket = io.connect('https://api.formcopilot.app/dynamic_table', {
            withCredentials: true,
            transports: ['websocket'],
            maxHttpBufferSize: 1e8,
            pingTimeout: 600000,
            pingInterval: 5000,
            auth: {
                token: localStorage.getItem("accessToken"),
            },
        });

        newSocket.on('connect', () => {
            console.log('SocketProvider: Connected to WebSocket.');
            setIsConnected(true);
            setIsReady(true);
        });

        newSocket.on('disconnect', () => {
            console.log('SocketProvider: Disconnected from WebSocket.');
            setIsConnected(false);
            setIsReady(false);
        });

        newSocket.on('error', (error) => {
            console.error('SocketProvider: WebSocket error:', error);
            setIsReady(false);
        });

        socketRef.current = newSocket;
        return newSocket;
    };

    useEffect(() => {
        const socket = initializeSocket();

        return () => {
            if (socket) {
                console.log('SocketProvider: Cleaning up socket connection');
                socket.removeAllListeners();
                socket.disconnect();
                socketRef.current = null;
                setIsConnected(false);
                setIsReady(false);
            }
        };
    }, []);

    return (
        <SocketContext.Provider value={{
            socket: socketRef.current,
            isConnected,
            isReady
        }}>
            {children}
        </SocketContext.Provider>
    );
};
